.title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 26px;
}
.img {
  width: 100%;
  height: auto;
}
.name {
  font-size: 24px;
  font-weight: 600;
  margin-top: 12px !important;
  margin-bottom: 0;
}
.designation {
  color: #707070;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 26px;
}
.section {
  border-bottom: 1px solid #707070;
}
.description {
  font-size: 20px;
  color: #707070;
  padding: 33px 0;
  text-align: justify;
}
.details {
  padding-top: 35px;
  padding-bottom: 35px;
}
.label {
  color: #000000;
  font-size: 20px;
  font-weight: 500;
}
.value {
  color: #707070;
  font-size: 20px;
  font-weight: 400;
}
.group {
  margin-bottom: 25px;
}

.button {
  max-width: 285px !important;
  margin-top: 52px !important;
  text-decoration: none !important;
  padding: 25px 75px;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  color: #ffffff;
  background-color: #000000;
  cursor: pointer;
}
.button:hover {
  color: #ffffff !important;
}
