.container {
  width: 100%;
}
.heading {
  width: 100%;
}
.title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 26px;
}
.img {
  border-radius: 10px;
  height: 100% !important;
  width: 100%;
}
.filter {
  margin-bottom: 24px;
}
.filter span {
  margin: 0 15px;
  cursor: pointer;
  font-size: 22px;
  font-weight: 500;
  color: #707070;
}
.items {
  display: flex;
  flex-wrap: wrap;
}
.item {
  position: relative;
  margin: 25px 25px;
  width: 375px;
  height: 285px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.noData {
  display: block;
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 25px;
}
.overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 10px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.486);
  height: 0%;
}
.overlay h2 {
  text-align: center;
  scale: 0;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.685);
}
.button {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  font-size: 24px;
  font-weight: 500;
  text-decoration: none !important;
  scale: 0;
  color: #ffffff;
  background-color: #212529;
}
.button:hover {
  color: #ffffff;
}
.item:hover .overlay {
  height: 100%;
  transition: 0.4s;
}
.item:hover .overlay h2 {
  scale: 1;
  transition: 0.4s;
}
.item:hover .overlay .button {
  scale: 1;
  transition: 0.4s;
}
