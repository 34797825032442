.Navbar {
  height: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  padding-left: 4vw;
  height: 100vh;
  left: 0;
  width: 21vw;
  background-color: rgb(255, 255, 255);
  z-index: 1002;
}
.nav-items {
  list-style: none;
  padding: 0;
  /* display: flex; */
}
.nav-item {
  margin-bottom: 27px;
}
.nav-items .nav-item a {
  text-decoration: none;
  font-weight: 400;
  font-size: 22px;
  color: #707070;
  cursor: pointer;
}
.active {
  color: #000000 !important;
}

.copyRight {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #707070;
  margin-top: 7.7vh;
}

@media only screen and (max-width: 768px) {
  .Navbar {
    width: 50vw;
  }
}
