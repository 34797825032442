.cardBody {
  padding: 40px 80px;
  display: flex;
}
@media only screen and (max-width: 600px) {
  .cardBody {
    padding: 65px 20px;
    display: flex;
  }
}
