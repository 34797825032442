.App {
  font-family: "Poppins", sans-serif !important;
}
.navBtn {
  position: fixed;
  padding: 10px 20px;
  right: 0;
}
.navBtn span {
  display: block;
  width: 20px;
  border-bottom: 2px solid #000000;
  padding: 4px 15px;
  content: " ";
  cursor: pointer;
}
.page {
  background-color: #f1f1f1;
  min-height: 100vh;
  padding-left: 21vw;
}

.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000007a;
  z-index: 1001;
}

@media only screen and (max-width: 667px) {
  .page {
    padding-left: 1px !important;
  }
}
