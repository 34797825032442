.heading {
  width: 100%;
}
.title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 26px;
  display: block;
}
.mapContainer {
  background-color: white;
  margin: auto !important;
  padding: 20px;
  width: 66vw;
  height: 20rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 2;
}
.formSec {
  margin-top: 55px !important;
}

.inputForm input,
textarea {
  width: 100%;
  padding: 22px 62px;
  margin: 15px 0 !important;
  border: #707070 0.5px solid !important;
  font-size: 18px;
  font-weight: 400;
}
.btnSec {
  margin: auto !important;
}
.button {
  border: none !important;
  max-width: 150px !important;
  padding: 15px 40px;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  color: #ffffff;
  background-color: #000000;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .mapContainer {
    width: 88vw;
  }
}
