.landing .row {
  min-height: 100vh;
  padding: 40px 0px;
}
.profileImage {
  max-width: 350px;
  padding: 2rem;
}
.title {
  font-size: 65px;
  font-weight: 800;
}
.description {
  max-width: 520px;
  font-size: 22px;
  font-weight: 400;
}
.socialLinks {
  margin-top: 12vh;
}
.socialLink {
  margin-right: 28px;
}
.socialLink img {
  height: 25px;
  width: 25px;
}
.liquid_shape {
  /* background-image: url("../../Assets/Image/profile.jpg"); */
  min-width: 300px;
  min-height: 300px;
  box-shadow: inset 0 0 0 10px #0000005e;
  border-radius: 60% 50% 50% 70% / 60% 40% 70% 60%;
  animation: liquid 8s ease-in-out infinite;
}

@keyframes liquid {
  0% {
    border-radius: 60% 50% 50% 70% / 60% 40% 70% 60%;
    background-size: 100% 100%;
  }
  25% {
    border-radius: 50% 60% 70% 48% / 45% 60% 50% 70%;
    background-size: 100% 100%;
  }
  50% {
    border-radius: 50% 80% 80% 48% / 61% 71% 63% 70%;
    background-size: 100% 100%;
  }
  75% {
    border-radius: 80% 60% 90% 48% / 70% 81% 60% 70%;
    background-size: 100% 100%;
  }
  100% {
    border-radius: 60% 50% 50% 70% / 60% 40% 70% 60%;
    background-size: 100% 100%;
  }
}
@media only screen and (max-width: 700px) {
  h2 {
    font-size: 35px !important;
    /* color: red; */
  }
  .profileImage img {
    max-width: 300px !important;
  }

  .description {
    max-width: 520px;
    font-size: 18px;
    font-weight: 400;
  }
}
